/* @font-face {
  font-family: "HelveticaNeue-Regular";
  src: url("./../assets/fonts/helveticaneue/HelveticaNeue-Regular.ttf");
} */

@font-face {
  font-family: "HelveticaNeue-Italic";
  src: url("./../assets/fonts/helveticaneue/HelveticaNeueIt.ttf");
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("./../assets/fonts/helveticaneue/HelveticaNeue Medium.ttf");
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("./../assets/fonts/helveticaneue/HelveticaNeuBold.ttf");
}

@font-face {
  font-family: "Copernicus-Bold";
  src: url("./../assets/fonts/Copernicus-Bold//Copernicus-Bold/Copernicus-Bold.ttf");
}

@font-face {
  font-family: "HelveticaNeue-Regular";
  font-style: normal;
  src: url("./../assets/fonts/helveticaneue/helvetica-neue-regular.woff")
    format("woff2");
}

@font-face {
  font-family: "HelveticaNeue-Regular";
  font-style: normal;
  src: url("./../assets/fonts/helveticaneue/helvetica-neue-regular.woff")
    format("woff2");
}

/* @media only screen and (orientation:landscape ){
  body {
    height: 100vw;
    transform: rotate(270deg);
  }
} */

@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  src: url("./../assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("woff2");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  src: url("./../assets/fonts/Montserrat/Montserrat-Medium.ttf") format("woff2");
}

body {
  background-color: #f2f1ee;
  font-family: Montserrat-Regular;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
}
#app {
  height: 100%;
}
a {
  color: #4e4a46;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #c94e50;
}
main {
  height: 100%;
  overflow: auto;
}
h1 {
  font-weight: 800;
  font-size: 3.75em;
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }
  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 30px;
  left: 20px;
  top: 38.5px;
}

.bm-burger-button:focus,
.bm-burger-button:hover {
  outline: none;
}

.bm-burger-button .bm-icon:focus,
.bm-burger-button .bm-icon:hover {
  outline: none;
}

.bm-burger-button button:focus,
.bm-burger-button button:hover {
  outline: none !important;
}
.bm-cross-button:focus,
.bm-cross-button:hover {
  outline: none;
}

.bm-cross-button .bm-icon:focus,
.bm-cross-button .bm-icon:hover {
  outline: none;
}

.bm-cross-button button:focus,
.bm-cross-button button:hover {
  outline: none !important;
}

.bm-cross-button {
  right: 20px !important;
  top: 20px !important;
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}
.bm-burger-bars {
  background: #373a47;
  height: 10% !important;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-menu {
  background: #ffffff;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu a:hover,
.bm-menu a:focus {
  text-decoration: none;
  background-color: #f2f1ee;
}
.bm-item-list a {
  padding: 0.8em;
}

.bm-item.accordion {
  margin-top: 50px;
}

.bm-item.accordion a {
  display: block;
}

.bm-item-list a span {
  margin-left: 40px;
  font-size: 15px;
  font-family: HelveticaNeue-Regular;
  text-transform: uppercase;
  color: #121212;
  letter-spacing: 0.1em;
  font-weight: 700;
}
a.bm-item {
  padding-left: 20px !important;
}
.bm-item:focus {
  outline: none;
}
.menu-1 .bm-cross {
  background: #bdc3c7;
}
.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.menu-2 .bm-cross {
  background: #999;
}
.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}
.menu-2 a {
  padding: 1em;
}
.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
.menu-3 .bm-cross {
  background: #888;
}
.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
.menu-3 i {
  opacity: 0.5;
}
.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
.menu-4 .bm-cross {
  background: #888;
}
.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 h2 i {
  margin-left: 0.2em;
}
.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}
.menu-4 a:hover,
.menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

#bubble .bm-cross,
#push .bm-cross {
  background: #999;
}
#bubble .bm-menu,
#push .bm-menu {
  padding: 3.4em 1em 0;
}
#bubble a,
#push a {
  padding: 1em;
}
#bubble i,
#push i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
#elastic .bm-cross {
  background: #888;
}
#elastic .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
#elastic i {
  opacity: 0.5;
}
#elastic span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
#scaleDown .bm-cross,
#scaleRotate .bm-cross,
#pushRotate .bm-cross,
#fallDown .bm-cross {
  background: #888;
}
#scaleDown h2,
#scaleRotate h2,
#pushRotate h2,
#fallDown h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#scaleDown h2 i,
#scaleRotate h2 i,
#pushRotate h2 i,
#fallDown h2 i {
  margin-left: 0.2em;
}
#scaleDown h2 span,
#scaleRotate h2 span,
#pushRotate h2 span,
#fallDown h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
#scaleDown a,
#scaleRotate a,
#pushRotate a,
#fallDown a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#scaleDown a span,
#scaleRotate a span,
#pushRotate a span,
#fallDown a span {
  letter-spacing: 1px;
  font-weight: 400;
}
#scaleDown a:hover,
#scaleRotate a:hover,
#pushRotate a:hover,
#fallDown a:hover,
#scaleDown a:focus,
#scaleRotate a:focus,
#pushRotate a:focus,
#fallDown a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

.bm-item.active {
  background-color: #f2f1ee;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  outline: 3px solid #3693a4;
  color: #000000;
  background-color: #ffffff;
}

.btn-primary.focus,
.btn-primary:focus {
  outline: 3px solid #3693a4;
  color: #000000;
  background-color: #ffffff;
}

.bm-item.row {
  margin: 0;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
  width: 95%;
}

.bm-burger-button {
  z-index: 2000 !important;
}
.bm-menu-wrap {
  z-index: 2001 !important;
  border-right: 1px solid #a9a8a6;
  width: 340px !important;
}

.dropdown-toggle.btn.btn-primary {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: -4px 0px 0px 0;
}

.dropdown-toggle.btn.btn-primary:hover,
.dropdown-toggle.btn.btn-primary:focus {
  border: none;
  outline: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu.show {
  display: block;
  max-height: 150px;
  overflow: auto;
}

.modal-header {
  justify-content: flex-end !important;
  background-color: #f2f1ee;
  border: none !important;
}

.modal-body {
  background-color: #f2f1ee;
  padding-bottom: 80px;
}

.modal-content {
  border-radius: 0 !important;
  width: 90%;
}

.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
}

.react-player-audio {
  display: none;
}

.react-player-video.hide {
  display: none;
}

.no-link:hover,
.no-link:focus {
  text-decoration: none;
  color: #000000;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
video::-webkit-media-controls {
  display: none !important;
}

.btn-primary:disabled {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: #d58a95;
}
.btn-primary:focus:disabled,
.btn-primary:hover:disabled {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: #d58a95;
  box-shadow: none;
}
